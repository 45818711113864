@import 'shared.scss';

#root {
  .MainTopNav {
    @include z-index(main-nav);
    padding: var(--space-md) var(--space-md);
    height: $navbar-height-mobile;
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;

    @include media('>=medium') {
      height: $navbar-height-tablet;
      padding: var(--space-md) calc(var(--space-md) * 1.3);
    }
    @include media('>=large') {
      height: $navbar-height-desktop;
      padding: var(--space-md) 0;
    }

    .only-aria-visible {
      clip-path: inset(100%);
      clip: rect(1px 1px 1px 1px); /* IE 6/7 */
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      @include media('>=large') {
        width: calc(100% - 2 * var(--component-padding));
        margin-left: auto;
        margin-right: auto;
        max-width: 90rem;
      }

      @include media('>=xlarge') {
        max-width: 94rem;
      }

      .nav-logo {
        top: px-to-rem(2);
        position: relative;
        width: px-to-rem(140);
        @include media('>=medium') {
          width: px-to-rem(170);
        }
        @include media('>=large') {
          width: px-to-rem(180);
        }
        @include media('>=xlarge') {
          width: px-to-rem(190);
        }
      }

      .nav-list {
        display: inline-block;

        .nav-item {
          display: inline-block;
          margin-left: 2rem;

          a {
            color: var(--grey-7);
            text-decoration: none;
            font-size: var(--text-md);

            &.active {
              color: $black;
              text-decoration: none;
            }

            &:hover:not(.active) {
              color: var(--grey-7);
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .PageOverlay {
    @include z-index(page-overlay);
  }

  .HamburgerMenu {
    top: $navbar-height-mobile;
    @include z-index(sidebar);
    height: calc(100% - #{$navbar-height-mobile});
    border-left: none;
    background-color: $white;
    text-align: center;
    padding: var(--space-xl) var(--space-lg);

    @include media('>=medium') {
      top: $navbar-height-tablet;
      width: px-to-rem(200);
    }
    // @include media('>=900px') {
    //   max-width: px-to-rem(340);
    // }

    @include media('>=large') {
      width: px-to-rem(260);
      top: $navbar-height-desktop;
    }
    // @include media('>=1400px') {
    //   max-width: px-to-rem(500);
    // }
    @include media('>=xlarge') {
      max-width: px-to-rem(440);
    }
    @include media('>=2200px') {
      max-width: px-to-rem(500);
    }
    .nav-list {
      width: auto;
      display: inline-block;
      text-align: left;
      @include media('>=medium') {
        display: block;
        text-align: left;
      }
      .nav-item {
        margin-bottom: var(--space-md);
        a {
          font-size: var(--text-md);
          color: darken($grey, 5%);
          @include media('>=medium') {
            font-size: calc(var(--text-lg) * 0.9);
          }
          // @include media('>=900px') {
          //   font-size: calc(var(--text-lg) * 1.2);
          // }
          // @include media('>=large') {
          //   font-size: calc(var(--text-lg) * 1.1);
          // }
          // @include media('>=1400px') {
          //   font-size: var(--text-xl);
          // }
          &.active {
            text-decoration: none;
            color: $black;
          }
        }
      }
    }
    .language-switcher {
      margin-top: var(--space-xl);
      text-align: center;
      @include media('>=medium') {
        text-align: left;
      }

      li {
        display: inline-block;
        &:first-child {
          margin-right: var(--space-xs);
        }
      }
    }
    .language-switcher__btn {
      font-size: var(--text-sm);
      text-transform: uppercase;
      font-weight: 400;
      color: darken($grey, 5%);

      &--active {
        color: $black;
        font-weight: 700;
      }
    }
  }

  .HamburgerButton {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    padding: 0;
    text-align: inherit;
    letter-spacing: inherit;
    font-size: inherit;
    text-transform: inherit;
    color: inherit;
    display: flex;

    &__text {
      span {
        font-weight: 400;
        margin-right: var(--space-xs);
        color: $white;
        font-size: calc(var(--text-md) * 0.9);
        text-transform: uppercase;
        @include media('>=medium') {
          font-size: var(--text-md);
        }
        @include media('>=large') {
          font-size: calc(var(--text-md) * 0.95);
        }
      }
    }

    &__bars {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 1.6rem;
      height: 1.2rem;
      @include media('>=medium') {
        width: 1.8rem;
        height: 1.4rem;
      }
      @include media('>=large') {
        top: px-to-rem(1);
        width: 2rem;
        height: 1.5rem;
      }

      .bar {
        width: 100%;
        height: 2px;
        background: $white;
        transform-origin: top left;
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
      }
    }

    &.close {
      .bar {
        &:nth-child(1) {
          transform: translateX(1px) rotate(45deg);
        }

        &:nth-child(2) {
          transform: scaleX(0);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }

    &.back {
      .bar {
        &:nth-child(1) {
          transform: translateX(-1px) translateY(10px) rotate(-45deg) scaleX(0.8);
        }

        &:nth-child(2) {
          transform: scaleX(0);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateX(1px) translateY(-9px) rotate(45deg) scaleX(0.8);
        }
      }
    }
  }

  .Footer {
    opacity: 0;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    @include z-index(foter);
    padding: 2rem $navbar-padding-mobile;
    text-align: center;
    background-color: var(--main-white);
    color: var(--main-black);

    @include media('>=medium') {
      padding: var(--space-lg);
    }

    @include media('>=large') {
      padding: var(--space-lg);
    }

    .footer-nav {
      color: var(--main-black);
      .nav-list {
        a {
          color: var(--main-black);
          font-size: var(--text-xs);
        }
      }
    }
    .footer-copyright {
      color: var(--main-black);
      font-size: var(--text-xs);
    }
  }

  .RotateScreen {
    @include z-index(rotate);
  }
}

.ReactModal__Overlay {
  background-color: rgba($black, 0.8) !important;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

.ReactModal__Content {
  position: relative !important;
  bottom: auto !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  overflow: visible !important;
}

.ril__outer {
  position: relative !important;
  background-color: transparent !important;
  bottom: auto !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  width: auto !important;
  height: auto !important;
}

.ril__inner {
  position: relative !important;
  bottom: auto !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  padding-bottom: px-to-rem(30);
  background-color: white;
  border-radius: 3px;
  @include media('>=medium') {
    padding-bottom: px-to-rem(40);
    border-radius: 4px;
  }

  img {
    transform: none !important;
    position: relative !important;
    bottom: auto !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    display: block !important;
    margin: auto;
    max-width: 90vw;
    max-height: 70vh;
    @include media('>=medium') {
      max-height: 80vh;
    }
    @include media('>=large') {
      max-height: 88vh;
      max-width: 92vw;
    }
  }
}

.ril__builtinButton {
  width: 28px !important;
}

.ril-caption-content {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: calc(var(--text-md) * 0.9);
  color: $black !important;
  padding-bottom: 5px !important;
  @include media('>=medium') {
    padding-bottom: 10px !important;
  }

  // text-transform: uppercase;
  // @include media('>=large') {
  //   padding-top: 25px !important;
  //   padding-bottom: 25px !important;
  // }
}

.ril__toolbar,
.ril__caption {
  background-color: transparent !important;
}

.ril__caption {
  background-color: transparent !important;
}

.ril__toolbar {
  height: 30px !important;
  width: 30px !important;
  top: -15px !important;
  right: -15px !important;
  left: auto !important;
  background-color: transparent !important;
}
.ril__toolbarSide {
  padding: 0 !important;
  margin: 0 !important;
  height: 30px !important;
}
.ril__toolbarLeftSide {
  padding: 0 !important;
  margin: 0 !important;
  height: 30px !important;
}
.ril-toolbar-right {
  position: absolute;
  padding-right: 0;
  bottom: px-to-rem(0);
  height: 30px;
  left: 0 !important;
  .ril-toolbar__item {
    border-radius: 50%;
    border: 2px solid $white;
    background-color: $black;
    height: 30px;
    width: 30px;
    line-height: 100%;
    font-size: 10px;
    position: relative;
    text-align: center;
    &:hover {
      cursor: pointer;
      background-color: lighten($black, 15%);
    }
    button {
      color: $white;
      position: relative;
      display: inline-block;
      width: 12px;
      top: 7px;
      right: 1px;
      height: 12px;
      opacity: 1;
    }
  }
}

.modal-video {
  background-color: rgba($black, 0.92) !important;
}

// .cookieConsent {
//   background: $black;
//   color: $white;
//   position: fixed;
//   text-align: center;
//   padding: var(--space-md);
//   bottom: px-to-rem(10) !important;
//   right: px-to-rem(10);
//   height: px-to-rem(145);
//   width: 94%;
//   @include media('>=medium') {
//     width: px-to-rem(340);
//     height: px-to-rem(150);
//   }
//   @include media('>=large') {
//     width: px-to-rem(400);
//     height: px-to-rem(175);
//   }
//   > div {
//     font-size: calc(var(--text-sm) * 1.3);
//     margin-bottom: px-to-rem(20);
//     @include media('>=large') {
//       font-size: calc(var(--text-sm) * 1.1);
//     }
//   }
//   button {
//     color: $black;
//     padding: px-to-rem(10) px-to-rem(20);
//     background-color: $white;
//     text-transform: uppercase;
//     font-size: var(--text-xs);
//   }
// }

.grecaptcha-badge {
  display: none !important;
}
