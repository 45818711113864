.flex {
  display: flex; }
  .flex > * {
    display: inline-block; }
  .flex .align-center {
    margin: auto;
    align-self: center; }
  .flex .align-left {
    margin-right: auto;
    align-self: center; }
  .flex .align-right {
    margin-left: auto;
    align-self: center; }
  .flex .align-x-center {
    margin-right: auto;
    margin-left: auto; }
  .flex .align-x-left {
    margin-right: auto; }
  .flex .align-x-right {
    margin-left: auto; }
  .flex .align-y-center {
    align-self: center; }
  .flex .align-y-top {
    align-self: flex-start; }
  .flex .align-y-bottom {
    align-self: flex-end; }

.flex--wrap {
  flex-wrap: wrap; }

.flex--column {
  flex-direction: column; }

.flex--end {
  justify-content: flex-end; }

.flex--center {
  justify-content: center;
  align-items: center; }

.flex--center-x {
  justify-content: center; }

.flex--center-y {
  align-items: center; }

.flex--space-between {
  justify-content: space-between; }

.flex--space-around {
  justify-content: space-around; }

.flex--space-evenly {
  justify-content: space-evenly; }

.flex--grow > * {
  flex-grow: 1; }

.flex--shrink-none > * {
  flex-shrink: 0; }

.flex-gap > *, .flex-gap--md > * {
  margin-right: var(--space-md); }
  .flex-gap > *:last-child, .flex-gap--md > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap, .flex--wrap.flex-gap--md {
  margin-bottom: calc(-1 * var(--space-md)); }
  .flex--wrap.flex-gap > *, .flex--wrap.flex-gap--md > * {
    margin-bottom: var(--space-md); }

.flex-gap--xxs > * {
  margin-right: var(--space-xxs); }
  .flex-gap--xxs > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--xxs {
  margin-bottom: calc(-1 * var(--space-xxs)); }
  .flex--wrap.flex-gap--xxs > * {
    margin-bottom: var(--space-xxs); }

.flex-gap--xs > * {
  margin-right: var(--space-xs); }
  .flex-gap--xs > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--xs {
  margin-bottom: calc(-1 * var(--space-xs)); }
  .flex--wrap.flex-gap--xs > * {
    margin-bottom: var(--space-xs); }

.flex-gap--sm > * {
  margin-right: var(--space-sm); }
  .flex-gap--sm > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--sm {
  margin-bottom: calc(-1 * var(--space-sm)); }
  .flex--wrap.flex-gap--sm > * {
    margin-bottom: var(--space-sm); }

.flex-gap--lg > * {
  margin-right: var(--space-lg); }
  .flex-gap--lg > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--lg {
  margin-bottom: calc(-1 * var(--space-lg)); }
  .flex--wrap.flex-gap--lg > * {
    margin-bottom: var(--space-lg); }

.flex-gap--xl > * {
  margin-right: var(--space-xl); }
  .flex-gap--xl > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--xl {
  margin-bottom: calc(-1 * var(--space-xl)); }
  .flex--wrap.flex-gap--xl > * {
    margin-bottom: var(--space-xl); }

.flex-item--end {
  align-self: flex-end; }

.flex-item--grow {
  flex-grow: 1; }

.flex-item--shrink-none {
  flex-shrink: 0; }

:root {
  --btn-font-size: 0.75em;
  --btn-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-md: calc(var(--btn-font-size) + 0.2em);
  --btn-lg: calc(var(--btn-font-size) + 0.4em);
  --btn-radius: var(--radius); }

button,
input[type='reset'],
input[type='submit'],
input[type='button'] {
  background: none;
  border: 0;
  cursor: pointer;
  overflow: visible;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-primary);
  width: auto;
  -webkit-appearance: none;
  background-clip: padding-box;
  border-radius: 0;
  box-sizing: border-box;
  line-height: normal !important;
  font-weight: bold; }
  button:active, button:focus,
  input[type='reset']:active,
  input[type='reset']:focus,
  input[type='submit']:active,
  input[type='submit']:focus,
  input[type='button']:active,
  input[type='button']:focus {
    text-decoration: none; }

.btn {
  display: inline-flex;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  padding: 13px 26px;
  padding: calc(var(--space-md) / 1.2) calc(var(--space-lg) * 1.1);
  border-radius: var(--btn-radius);
  font-size: var(--btn-font-size);
  color: var(--color-link);
  transition: 0.2s; }
  @media (min-width: 768px) {
    .btn {
      padding: 15px 35px;
      padding: calc(var(--space-md) / 1.2) calc(var(--space-lg) * 1.2); } }
  @media (min-width: 1100px) {
    .btn {
      padding: 20px 50px;
      padding: calc(var(--space-md) / 1.2) var(--space-xl); } }
  .btn:active {
    transition: none; }
  .btn .icon {
    /* icon inherits color of button label */
    color: inherit;
    flex-shrink: 0; }

/* themes */
.btn--primary {
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-label);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .btn--primary:visited {
    color: var(--btn-primary-label); }
  .btn--primary:hover {
    background-color: var(--btn-primary-hover); }
  .btn--primary:active {
    background-color: var(--btn-primary-active); }

.btn--secondary {
  background-color: var(--btn-secondary-bg);
  color: var(--btn-secondary-label); }
  .btn--secondary:visited {
    color: var(--btn-secondary-label); }
  .btn--secondary:active {
    background-color: var(--btn-secondary-active); }

.btn--accent {
  background-color: var(--btn-accent-bg);
  color: var(--btn-accent-label);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .btn--accent:visited {
    color: var(--btn-accent-label); }
  .btn--accent:hover {
    background-color: var(--btn-accent-hover); }
  .btn--accent:active {
    background-color: var(--btn-accent-active); }

/* feedback */
.btn[disabled] {
  cursor: not-allowed;
  background-color: var(--btn-disabled-bg);
  color: var(--btn-disabled-label);
  box-shadow: none;
  opacity: 0.6; }
  .btn[disabled]:visited {
    color: var(--btn-disabled-label); }

/* button size */
.btn--sm {
  font-size: var(--btn-sm); }

.btn--md {
  font-size: var(--btn-md); }

.btn--lg {
  font-size: var(--btn-lg); }

.btn--full-width {
  display: flex;
  width: 100%;
  justify-content: center; }

/* buttons group */
.btns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(-1 * var(--space-xs)); }
  .btns > * {
    margin-right: var(--space-xs);
    margin-bottom: var(--space-xs); }
    .btns > *:last-of-type {
      margin-right: 0; }

:root {
  --color-blue: #1E90FF;
  --color-green: #32CD32;
  --color-red: #FF0000;
  --main-white: white;
  --main-black: #000000;
  --main-gray: #474747;
  --grey-9: #232323;
  --grey-8: #353535;
  --grey-7: #474747;
  --grey-6: #757575;
  --grey-5: #a3a3a3;
  --grey-4: #bfbfbf;
  --grey-3: #dadada;
  --grey-2: #ededed;
  --grey-1: #f6f6f6; }

.color-text-green {
  color: var(--color-green); }

.color-text-blue {
  color: var(--color-blue); }

.form-control {
  padding: var(--space-xs) var(--space-sm);
  background: var(--color-bg);
  border: 2px solid var(--color-contrast-low);
  width: 100%; }
  .form-control:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px var(--color-primary-a20); }
  .form-control::-webkit-input-placeholder {
    color: var(--color-contrast-medium); }
  .form-control::-moz-placeholder {
    opacity: 1;
    color: var(--color-contrast-medium); }
  .form-control:-ms-input-placeholder {
    color: var(--color-contrast-medium); }
  .form-control:-moz-placeholder {
    color: var(--color-contrast-medium); }

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed; }

.form-control[aria-invalid="true"] {
  border-color: var(--color-error); }
  .form-control[aria-invalid="true"]:focus {
    box-shadow: 0 0 0 3px var(--color-error-a20); }

.form-legend {
  color: var(--color-contrast-higher);
  line-height: 1.2;
  font-size: var(--text-md);
  margin-bottom: var(--space-xxs); }

.form-label {
  display: inline-block;
  font-size: var(--text-sm);
  margin-bottom: var(--space-xxxs); }

.form__msg-error {
  background-color: var(--color-error);
  color: var(--color-white);
  font-size: var(--text-sm);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--space-xs);
  margin-top: var(--space-sm);
  border-radius: 0.25em;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px); }
  .form__msg-error::before {
    content: '';
    position: absolute;
    left: var(--space-sm);
    top: 0;
    transform: translateY(-100%);
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: var(--color-error); }

.form__msg-error--is-visible {
  position: relative;
  clip: auto; }

:root {
  --select-icon-size: 1em;
  --select-icon-right-margin: var(--space-sm); }

.select {
  position: relative; }
  .select select {
    padding-right: calc(var(--select-icon-size) + var(--select-icon-right-margin) * 2); }
  .select .icon {
    width: var(--select-icon-size);
    height: var(--select-icon-size);
    position: absolute;
    right: var(--select-icon-right-margin);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; }

.radio-list > *, .checkbox-list > * {
  position: relative;
  display: flex;
  align-items: baseline;
  margin-bottom: var(--space-xxs); }

.radio-list > *:last-of-type, .checkbox-list > *:last-of-type {
  margin-bottom: 0; }

.radio-list label, .checkbox-list label {
  line-height: var(--body-line-height);
  user-select: none; }

.radio-list input, .checkbox-list input {
  vertical-align: top;
  margin-right: var(--space-xxxs);
  flex-shrink: 0; }

.radio-list.radio-list--inline, .radio-list.checkbox-list--inline, .checkbox-list.radio-list--inline, .checkbox-list.checkbox-list--inline {
  display: flex;
  margin-bottom: calc(-1 * var(--space-md)); }
  .radio-list.radio-list--inline > *, .radio-list.checkbox-list--inline > *, .checkbox-list.radio-list--inline > *, .checkbox-list.checkbox-list--inline > * {
    margin: 0 var(--space-md) var(--space-md) 0; }

:root {
  --container-width-xs: 37.5rem;
  --container-width-sm: 45rem;
  --container-width-md: 64rem;
  --container-width-lg: 80rem;
  --container-width-xl: 90rem; }

.container {
  width: calc(100% - 2*var(--component-padding));
  margin-left: auto;
  margin-right: auto; }

.container--full-width {
  width: 100%; }

.container--xs {
  max-width: var(--container-width-xs); }

.container--sm {
  max-width: var(--container-width-sm); }

.container--md {
  max-width: var(--container-width-md); }

.container--lg {
  max-width: var(--container-width-lg); }

.container--xl {
  max-width: var(--container-width-xl); }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--space-md) * -1) 0 0 calc(var(--space-md) * -1); }
  .grid > * {
    padding: var(--space-md) 0 0 var(--space-md);
    background-clip: content-box;
    flex-basis: 100%; }

.grid-gap--none {
  margin: 0; }
  .grid-gap--none > * {
    padding: 0; }

.grid-gap--xs {
  margin: calc(var(--space-xs) * -1) 0 0 calc(var(--space-xs) * -1); }
  .grid-gap--xs > * {
    padding: var(--space-xs) 0 0 var(--space-xs); }

.grid-gap--sm {
  margin: calc(var(--space-sm) * -1) 0 0 calc(var(--space-sm) * -1); }
  .grid-gap--sm > * {
    padding: var(--space-sm) 0 0 var(--space-sm); }

.grid-gap--lg {
  margin: calc(var(--space-lg) * -1) 0 0 calc(var(--space-lg) * -1); }
  .grid-gap--lg > * {
    padding: var(--space-lg) 0 0 var(--space-lg); }

.grid-gap--xl {
  margin: calc(var(--space-xl) * -1) 0 0 calc(var(--space-xl) * -1); }
  .grid-gap--xl > * {
    padding: var(--space-xl) 0 0 var(--space-xl); }

.col--1 {
  flex-basis: 8.33%;
  max-width: 8.33%; }

.col--2 {
  flex-basis: 16.66%;
  max-width: 16.66%; }

.col--3 {
  flex-basis: 25%;
  max-width: 25%; }

.col--4 {
  flex-basis: 33.33%;
  max-width: 33.33%; }

.col--5 {
  flex-basis: 41.66%;
  max-width: 41.66%; }

.col--6 {
  flex-basis: 50%;
  max-width: 50%; }

.col--7 {
  flex-basis: 58.33%;
  max-width: 58.33%; }

.col--8 {
  flex-basis: 66.66%;
  max-width: 66.66%; }

.col--9 {
  flex-basis: 75%;
  max-width: 75%; }

.col--10 {
  flex-basis: 83.33%;
  max-width: 83.33%; }

.col--11 {
  flex-basis: 91.66%;
  max-width: 91.66%; }

.col--12 {
  flex-basis: 100%;
  max-width: 100%; }

@media (min-width: 414px) {
  .col--sm-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--sm-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--sm-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--sm-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--sm-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--sm-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--sm-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--sm-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--sm-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col--md-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--md-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--md-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--md-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--md-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--md-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--md-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--md-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--md-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1100px) {
  .col--lg-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--lg-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--lg-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--lg-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--lg-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--lg-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--lg-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--lg-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--lg-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1920px) {
  .col--xl-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--xl-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--xl-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--xl-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--xl-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--xl-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--xl-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--xl-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--xl-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--xl-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--xl-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--xl-12 {
    flex-basis: 100%;
    max-width: 100%; } }

:root {
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px; }

/* icon size */
.icon--xs {
  font-size: var(--icon-xs); }

.icon--sm {
  font-size: var(--icon-sm); }

.icon--md {
  font-size: var(--icon-md); }

.icon--lg {
  font-size: var(--icon-lg); }

.icon--xl {
  font-size: var(--icon-xl); }

/* color */
.icon {
  color: var(--color-icon-primary);
  fill: var(--color-icon-primary); }

.icon-text {
  display: inline-flex;
  align-items: center; }
  .icon-text > *:nth-child(2) {
    margin-left: var(--space-xxs); }
  .icon-text .icon {
    color: inherit;
    flex-shrink: 0; }
  .icon-text.icon-text-gap--none > *:nth-child(2) {
    margin-left: 0; }

.icon--is-spinning {
  animation: icon-spin 1s infinite linear; }

@keyframes icon-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.icon use {
  color: inherit;
  fill: currentColor; }

:root {
  --space-unit: 1.5em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-lg); }
  @media (min-width: 320px) {
    :root {
      --space-unit: 1.25em; } }
  @media (min-width: 1100px) {
    :root {
      --space-unit: 1.5em; } }

:root {
  --font-primary: Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-secondary: Helvetica Neue, Helvetica, Arial, sans-serif;
  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  /* type scale */
  --text-xxs: calc(var(--text-xs) / var(--text-scale-ratio));
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --body-line-height: 1.6;
  --heading-line-height: 1.3;
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1; }

body {
  font-size: 1em; }
  @media (min-width: 768px) {
    body {
      font-size: 1.05em; } }
  @media (min-width: 1100px) {
    body {
      font-size: 1.25em; } }
  @media (min-width: 1920px) {
    body {
      font-size: 1.3em; } }

h1,
h2,
h3,
h4 {
  font-weight: 500;
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxxs);
  line-height: var(--heading-line-height); }

p {
  line-height: var(--body-line-height); }

b,
strong {
  font-weight: bold; }

/* utility classes */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

[hidden] {
  display: none !important; }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./../assets/fonts/Roboto/Roboto-Light.woff") format("woff"), url("./../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./../assets/fonts/Roboto/Roboto-Regular.woff") format("woff"), url("./../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./../assets/fonts/Roboto/Roboto-Bold.woff") format("woff"), url("./../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  unicode-range: U+0000-00ff, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

.flex {
  display: flex; }
  .flex > * {
    display: inline-block; }
  .flex .align-center {
    margin: auto;
    align-self: center; }
  .flex .align-left {
    margin-right: auto;
    align-self: center; }
  .flex .align-right {
    margin-left: auto;
    align-self: center; }
  .flex .align-x-center {
    margin-right: auto;
    margin-left: auto; }
  .flex .align-x-left {
    margin-right: auto; }
  .flex .align-x-right {
    margin-left: auto; }
  .flex .align-y-center {
    align-self: center; }
  .flex .align-y-top {
    align-self: flex-start; }
  .flex .align-y-bottom {
    align-self: flex-end; }

.flex--wrap {
  flex-wrap: wrap; }

.flex--column {
  flex-direction: column; }

.flex--end {
  justify-content: flex-end; }

.flex--center {
  justify-content: center;
  align-items: center; }

.flex--center-x {
  justify-content: center; }

.flex--center-y {
  align-items: center; }

.flex--space-between {
  justify-content: space-between; }

.flex--space-around {
  justify-content: space-around; }

.flex--space-evenly {
  justify-content: space-evenly; }

.flex--grow > * {
  flex-grow: 1; }

.flex--shrink-none > * {
  flex-shrink: 0; }

.flex-gap > *, .flex-gap--md > * {
  margin-right: var(--space-md); }
  .flex-gap > *:last-child, .flex-gap--md > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap, .flex--wrap.flex-gap--md {
  margin-bottom: calc(-1 * var(--space-md)); }
  .flex--wrap.flex-gap > *, .flex--wrap.flex-gap--md > * {
    margin-bottom: var(--space-md); }

.flex-gap--xxs > * {
  margin-right: var(--space-xxs); }
  .flex-gap--xxs > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--xxs {
  margin-bottom: calc(-1 * var(--space-xxs)); }
  .flex--wrap.flex-gap--xxs > * {
    margin-bottom: var(--space-xxs); }

.flex-gap--xs > * {
  margin-right: var(--space-xs); }
  .flex-gap--xs > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--xs {
  margin-bottom: calc(-1 * var(--space-xs)); }
  .flex--wrap.flex-gap--xs > * {
    margin-bottom: var(--space-xs); }

.flex-gap--sm > * {
  margin-right: var(--space-sm); }
  .flex-gap--sm > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--sm {
  margin-bottom: calc(-1 * var(--space-sm)); }
  .flex--wrap.flex-gap--sm > * {
    margin-bottom: var(--space-sm); }

.flex-gap--lg > * {
  margin-right: var(--space-lg); }
  .flex-gap--lg > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--lg {
  margin-bottom: calc(-1 * var(--space-lg)); }
  .flex--wrap.flex-gap--lg > * {
    margin-bottom: var(--space-lg); }

.flex-gap--xl > * {
  margin-right: var(--space-xl); }
  .flex-gap--xl > *:last-child {
    margin-right: 0; }

.flex--wrap.flex-gap--xl {
  margin-bottom: calc(-1 * var(--space-xl)); }
  .flex--wrap.flex-gap--xl > * {
    margin-bottom: var(--space-xl); }

.flex-item--end {
  align-self: flex-end; }

.flex-item--grow {
  flex-grow: 1; }

.flex-item--shrink-none {
  flex-shrink: 0; }

:root {
  --btn-font-size: 0.75em;
  --btn-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-md: calc(var(--btn-font-size) + 0.2em);
  --btn-lg: calc(var(--btn-font-size) + 0.4em);
  --btn-radius: var(--radius); }

button,
input[type='reset'],
input[type='submit'],
input[type='button'] {
  background: none;
  border: 0;
  cursor: pointer;
  overflow: visible;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-primary);
  width: auto;
  -webkit-appearance: none;
  background-clip: padding-box;
  border-radius: 0;
  box-sizing: border-box;
  line-height: normal !important;
  font-weight: bold; }
  button:active, button:focus,
  input[type='reset']:active,
  input[type='reset']:focus,
  input[type='submit']:active,
  input[type='submit']:focus,
  input[type='button']:active,
  input[type='button']:focus {
    text-decoration: none; }

.btn {
  display: inline-flex;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  padding: 13px 26px;
  padding: calc(var(--space-md) / 1.2) calc(var(--space-lg) * 1.1);
  border-radius: var(--btn-radius);
  font-size: var(--btn-font-size);
  color: var(--color-link);
  transition: 0.2s; }
  @media (min-width: 768px) {
    .btn {
      padding: 15px 35px;
      padding: calc(var(--space-md) / 1.2) calc(var(--space-lg) * 1.2); } }
  @media (min-width: 1100px) {
    .btn {
      padding: 20px 50px;
      padding: calc(var(--space-md) / 1.2) var(--space-xl); } }
  .btn:active {
    transition: none; }
  .btn .icon {
    /* icon inherits color of button label */
    color: inherit;
    flex-shrink: 0; }

/* themes */
.btn--primary {
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-label);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .btn--primary:visited {
    color: var(--btn-primary-label); }
  .btn--primary:hover {
    background-color: var(--btn-primary-hover); }
  .btn--primary:active {
    background-color: var(--btn-primary-active); }

.btn--secondary {
  background-color: var(--btn-secondary-bg);
  color: var(--btn-secondary-label); }
  .btn--secondary:visited {
    color: var(--btn-secondary-label); }
  .btn--secondary:active {
    background-color: var(--btn-secondary-active); }

.btn--accent {
  background-color: var(--btn-accent-bg);
  color: var(--btn-accent-label);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .btn--accent:visited {
    color: var(--btn-accent-label); }
  .btn--accent:hover {
    background-color: var(--btn-accent-hover); }
  .btn--accent:active {
    background-color: var(--btn-accent-active); }

/* feedback */
.btn[disabled] {
  cursor: not-allowed;
  background-color: var(--btn-disabled-bg);
  color: var(--btn-disabled-label);
  box-shadow: none;
  opacity: 0.6; }
  .btn[disabled]:visited {
    color: var(--btn-disabled-label); }

/* button size */
.btn--sm {
  font-size: var(--btn-sm); }

.btn--md {
  font-size: var(--btn-md); }

.btn--lg {
  font-size: var(--btn-lg); }

.btn--full-width {
  display: flex;
  width: 100%;
  justify-content: center; }

/* buttons group */
.btns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(-1 * var(--space-xs)); }
  .btns > * {
    margin-right: var(--space-xs);
    margin-bottom: var(--space-xs); }
    .btns > *:last-of-type {
      margin-right: 0; }

:root {
  --color-blue: #1E90FF;
  --color-green: #32CD32;
  --color-red: #FF0000;
  --main-white: white;
  --main-black: #000000;
  --main-gray: #474747;
  --grey-9: #232323;
  --grey-8: #353535;
  --grey-7: #474747;
  --grey-6: #757575;
  --grey-5: #a3a3a3;
  --grey-4: #bfbfbf;
  --grey-3: #dadada;
  --grey-2: #ededed;
  --grey-1: #f6f6f6; }

.color-text-green {
  color: var(--color-green); }

.color-text-blue {
  color: var(--color-blue); }

.form-control {
  padding: var(--space-xs) var(--space-sm);
  background: var(--color-bg);
  border: 2px solid var(--color-contrast-low);
  width: 100%; }
  .form-control:focus {
    outline: none;
    border-color: var(--color-primary);
    box-shadow: 0 0 0 3px var(--color-primary-a20); }
  .form-control::-webkit-input-placeholder {
    color: var(--color-contrast-medium); }
  .form-control::-moz-placeholder {
    opacity: 1;
    color: var(--color-contrast-medium); }
  .form-control:-ms-input-placeholder {
    color: var(--color-contrast-medium); }
  .form-control:-moz-placeholder {
    color: var(--color-contrast-medium); }

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed; }

.form-control[aria-invalid="true"] {
  border-color: var(--color-error); }
  .form-control[aria-invalid="true"]:focus {
    box-shadow: 0 0 0 3px var(--color-error-a20); }

.form-legend {
  color: var(--color-contrast-higher);
  line-height: 1.2;
  font-size: var(--text-md);
  margin-bottom: var(--space-xxs); }

.form-label {
  display: inline-block;
  font-size: var(--text-sm);
  margin-bottom: var(--space-xxxs); }

.form__msg-error {
  background-color: var(--color-error);
  color: var(--color-white);
  font-size: var(--text-sm);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--space-xs);
  margin-top: var(--space-sm);
  border-radius: 0.25em;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px); }
  .form__msg-error::before {
    content: '';
    position: absolute;
    left: var(--space-sm);
    top: 0;
    transform: translateY(-100%);
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: var(--color-error); }

.form__msg-error--is-visible {
  position: relative;
  clip: auto; }

:root {
  --select-icon-size: 1em;
  --select-icon-right-margin: var(--space-sm); }

.select {
  position: relative; }
  .select select {
    padding-right: calc(var(--select-icon-size) + var(--select-icon-right-margin) * 2); }
  .select .icon {
    width: var(--select-icon-size);
    height: var(--select-icon-size);
    position: absolute;
    right: var(--select-icon-right-margin);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; }

.radio-list > *, .checkbox-list > * {
  position: relative;
  display: flex;
  align-items: baseline;
  margin-bottom: var(--space-xxs); }

.radio-list > *:last-of-type, .checkbox-list > *:last-of-type {
  margin-bottom: 0; }

.radio-list label, .checkbox-list label {
  line-height: var(--body-line-height);
  user-select: none; }

.radio-list input, .checkbox-list input {
  vertical-align: top;
  margin-right: var(--space-xxxs);
  flex-shrink: 0; }

.radio-list.radio-list--inline, .radio-list.checkbox-list--inline, .checkbox-list.radio-list--inline, .checkbox-list.checkbox-list--inline {
  display: flex;
  margin-bottom: calc(-1 * var(--space-md)); }
  .radio-list.radio-list--inline > *, .radio-list.checkbox-list--inline > *, .checkbox-list.radio-list--inline > *, .checkbox-list.checkbox-list--inline > * {
    margin: 0 var(--space-md) var(--space-md) 0; }

:root {
  --container-width-xs: 37.5rem;
  --container-width-sm: 45rem;
  --container-width-md: 64rem;
  --container-width-lg: 80rem;
  --container-width-xl: 90rem; }

.container {
  width: calc(100% - 2*var(--component-padding));
  margin-left: auto;
  margin-right: auto; }

.container--full-width {
  width: 100%; }

.container--xs {
  max-width: var(--container-width-xs); }

.container--sm {
  max-width: var(--container-width-sm); }

.container--md {
  max-width: var(--container-width-md); }

.container--lg {
  max-width: var(--container-width-lg); }

.container--xl {
  max-width: var(--container-width-xl); }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--space-md) * -1) 0 0 calc(var(--space-md) * -1); }
  .grid > * {
    padding: var(--space-md) 0 0 var(--space-md);
    background-clip: content-box;
    flex-basis: 100%; }

.grid-gap--none {
  margin: 0; }
  .grid-gap--none > * {
    padding: 0; }

.grid-gap--xs {
  margin: calc(var(--space-xs) * -1) 0 0 calc(var(--space-xs) * -1); }
  .grid-gap--xs > * {
    padding: var(--space-xs) 0 0 var(--space-xs); }

.grid-gap--sm {
  margin: calc(var(--space-sm) * -1) 0 0 calc(var(--space-sm) * -1); }
  .grid-gap--sm > * {
    padding: var(--space-sm) 0 0 var(--space-sm); }

.grid-gap--lg {
  margin: calc(var(--space-lg) * -1) 0 0 calc(var(--space-lg) * -1); }
  .grid-gap--lg > * {
    padding: var(--space-lg) 0 0 var(--space-lg); }

.grid-gap--xl {
  margin: calc(var(--space-xl) * -1) 0 0 calc(var(--space-xl) * -1); }
  .grid-gap--xl > * {
    padding: var(--space-xl) 0 0 var(--space-xl); }

.col--1 {
  flex-basis: 8.33%;
  max-width: 8.33%; }

.col--2 {
  flex-basis: 16.66%;
  max-width: 16.66%; }

.col--3 {
  flex-basis: 25%;
  max-width: 25%; }

.col--4 {
  flex-basis: 33.33%;
  max-width: 33.33%; }

.col--5 {
  flex-basis: 41.66%;
  max-width: 41.66%; }

.col--6 {
  flex-basis: 50%;
  max-width: 50%; }

.col--7 {
  flex-basis: 58.33%;
  max-width: 58.33%; }

.col--8 {
  flex-basis: 66.66%;
  max-width: 66.66%; }

.col--9 {
  flex-basis: 75%;
  max-width: 75%; }

.col--10 {
  flex-basis: 83.33%;
  max-width: 83.33%; }

.col--11 {
  flex-basis: 91.66%;
  max-width: 91.66%; }

.col--12 {
  flex-basis: 100%;
  max-width: 100%; }

@media (min-width: 414px) {
  .col--sm-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--sm-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--sm-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--sm-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--sm-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--sm-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--sm-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--sm-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--sm-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col--md-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--md-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--md-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--md-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--md-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--md-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--md-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--md-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--md-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1100px) {
  .col--lg-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--lg-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--lg-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--lg-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--lg-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--lg-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--lg-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--lg-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--lg-12 {
    flex-basis: 100%;
    max-width: 100%; } }

@media (min-width: 1920px) {
  .col--xl-1 {
    flex-basis: 8.33%;
    max-width: 8.33%; }
  .col--xl-2 {
    flex-basis: 16.66%;
    max-width: 16.66%; }
  .col--xl-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col--xl-4 {
    flex-basis: 33.33%;
    max-width: 33.33%; }
  .col--xl-5 {
    flex-basis: 41.66%;
    max-width: 41.66%; }
  .col--xl-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col--xl-7 {
    flex-basis: 58.33%;
    max-width: 58.33%; }
  .col--xl-8 {
    flex-basis: 66.66%;
    max-width: 66.66%; }
  .col--xl-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col--xl-10 {
    flex-basis: 83.33%;
    max-width: 83.33%; }
  .col--xl-11 {
    flex-basis: 91.66%;
    max-width: 91.66%; }
  .col--xl-12 {
    flex-basis: 100%;
    max-width: 100%; } }

:root {
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px; }

/* icon size */
.icon--xs {
  font-size: var(--icon-xs); }

.icon--sm {
  font-size: var(--icon-sm); }

.icon--md {
  font-size: var(--icon-md); }

.icon--lg {
  font-size: var(--icon-lg); }

.icon--xl {
  font-size: var(--icon-xl); }

/* color */
.icon {
  color: var(--color-icon-primary);
  fill: var(--color-icon-primary); }

.icon-text {
  display: inline-flex;
  align-items: center; }
  .icon-text > *:nth-child(2) {
    margin-left: var(--space-xxs); }
  .icon-text .icon {
    color: inherit;
    flex-shrink: 0; }
  .icon-text.icon-text-gap--none > *:nth-child(2) {
    margin-left: 0; }

.icon--is-spinning {
  animation: icon-spin 1s infinite linear; }

@keyframes icon-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.icon use {
  color: inherit;
  fill: currentColor; }

:root {
  --space-unit: 1.5em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-lg); }
  @media (min-width: 320px) {
    :root {
      --space-unit: 1.25em; } }
  @media (min-width: 1100px) {
    :root {
      --space-unit: 1.5em; } }

:root {
  --font-primary: Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-secondary: Helvetica Neue, Helvetica, Arial, sans-serif;
  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  /* type scale */
  --text-xxs: calc(var(--text-xs) / var(--text-scale-ratio));
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --body-line-height: 1.6;
  --heading-line-height: 1.3;
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1; }

body {
  font-size: 1em; }
  @media (min-width: 768px) {
    body {
      font-size: 1.05em; } }
  @media (min-width: 1100px) {
    body {
      font-size: 1.25em; } }
  @media (min-width: 1920px) {
    body {
      font-size: 1.3em; } }

h1,
h2,
h3,
h4 {
  font-weight: 500;
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxxs);
  line-height: var(--heading-line-height); }

p {
  line-height: var(--body-line-height); }

b,
strong {
  font-weight: bold; }

/* utility classes */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

[hidden] {
  display: none !important; }

#root .MainTopNav {
  z-index: 4;
  padding: var(--space-md) var(--space-md);
  height: 4rem;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box; }
  @media (min-width: 768px) {
    #root .MainTopNav {
      height: 4.5rem;
      padding: var(--space-md) calc(var(--space-md) * 1.3); } }
  @media (min-width: 1100px) {
    #root .MainTopNav {
      height: 5rem;
      padding: var(--space-md) 0; } }
  #root .MainTopNav .only-aria-visible {
    clip-path: inset(100%);
    clip: rect(1px 1px 1px 1px);
    /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px; }
  #root .MainTopNav .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
    @media (min-width: 1100px) {
      #root .MainTopNav .nav {
        width: calc(100% - 2 * var(--component-padding));
        margin-left: auto;
        margin-right: auto;
        max-width: 90rem; } }
    @media (min-width: 1920px) {
      #root .MainTopNav .nav {
        max-width: 94rem; } }
    #root .MainTopNav .nav .nav-logo {
      top: 0.125rem;
      position: relative;
      width: 8.75rem; }
      @media (min-width: 768px) {
        #root .MainTopNav .nav .nav-logo {
          width: 10.625rem; } }
      @media (min-width: 1100px) {
        #root .MainTopNav .nav .nav-logo {
          width: 11.25rem; } }
      @media (min-width: 1920px) {
        #root .MainTopNav .nav .nav-logo {
          width: 11.875rem; } }
    #root .MainTopNav .nav .nav-list {
      display: inline-block; }
      #root .MainTopNav .nav .nav-list .nav-item {
        display: inline-block;
        margin-left: 2rem; }
        #root .MainTopNav .nav .nav-list .nav-item a {
          color: var(--grey-7);
          text-decoration: none;
          font-size: var(--text-md); }
          #root .MainTopNav .nav .nav-list .nav-item a.active {
            color: #000;
            text-decoration: none; }
          #root .MainTopNav .nav .nav-list .nav-item a:hover:not(.active) {
            color: var(--grey-7);
            text-decoration: none; }

#root .PageOverlay {
  z-index: 3; }

#root .HamburgerMenu {
  top: 4rem;
  z-index: 5;
  height: calc(100% - 4rem);
  border-left: none;
  background-color: #fff;
  text-align: center;
  padding: var(--space-xl) var(--space-lg); }
  @media (min-width: 768px) {
    #root .HamburgerMenu {
      top: 4.5rem;
      width: 12.5rem; } }
  @media (min-width: 1100px) {
    #root .HamburgerMenu {
      width: 16.25rem;
      top: 5rem; } }
  @media (min-width: 1920px) {
    #root .HamburgerMenu {
      max-width: 27.5rem; } }
  @media (min-width: 2200px) {
    #root .HamburgerMenu {
      max-width: 31.25rem; } }
  #root .HamburgerMenu .nav-list {
    width: auto;
    display: inline-block;
    text-align: left; }
    @media (min-width: 768px) {
      #root .HamburgerMenu .nav-list {
        display: block;
        text-align: left; } }
    #root .HamburgerMenu .nav-list .nav-item {
      margin-bottom: var(--space-md); }
      #root .HamburgerMenu .nav-list .nav-item a {
        font-size: var(--text-md);
        color: #7b7b7b; }
        @media (min-width: 768px) {
          #root .HamburgerMenu .nav-list .nav-item a {
            font-size: calc(var(--text-lg) * 0.9); } }
        #root .HamburgerMenu .nav-list .nav-item a.active {
          text-decoration: none;
          color: #000; }
  #root .HamburgerMenu .language-switcher {
    margin-top: var(--space-xl);
    text-align: center; }
    @media (min-width: 768px) {
      #root .HamburgerMenu .language-switcher {
        text-align: left; } }
    #root .HamburgerMenu .language-switcher li {
      display: inline-block; }
      #root .HamburgerMenu .language-switcher li:first-child {
        margin-right: var(--space-xs); }
  #root .HamburgerMenu .language-switcher__btn {
    font-size: var(--text-sm);
    text-transform: uppercase;
    font-weight: 400;
    color: #7b7b7b; }
    #root .HamburgerMenu .language-switcher__btn--active {
      color: #000;
      font-weight: 700; }

#root .HamburgerButton {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  color: inherit;
  display: flex; }
  #root .HamburgerButton__text span {
    font-weight: 400;
    margin-right: var(--space-xs);
    color: #fff;
    font-size: calc(var(--text-md) * 0.9);
    text-transform: uppercase; }
    @media (min-width: 768px) {
      #root .HamburgerButton__text span {
        font-size: var(--text-md); } }
    @media (min-width: 1100px) {
      #root .HamburgerButton__text span {
        font-size: calc(var(--text-md) * 0.95); } }
  #root .HamburgerButton__bars {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.6rem;
    height: 1.2rem; }
    @media (min-width: 768px) {
      #root .HamburgerButton__bars {
        width: 1.8rem;
        height: 1.4rem; } }
    @media (min-width: 1100px) {
      #root .HamburgerButton__bars {
        top: 0.0625rem;
        width: 2rem;
        height: 1.5rem; } }
    #root .HamburgerButton__bars .bar {
      width: 100%;
      height: 2px;
      background: #fff;
      transform-origin: top left;
      transition: transform 0.2s ease-out, opacity 0.2s ease-out; }
  #root .HamburgerButton.close .bar:nth-child(1) {
    transform: translateX(1px) rotate(45deg); }
  #root .HamburgerButton.close .bar:nth-child(2) {
    transform: scaleX(0);
    opacity: 0; }
  #root .HamburgerButton.close .bar:nth-child(3) {
    transform: rotate(-45deg); }
  #root .HamburgerButton.back .bar:nth-child(1) {
    transform: translateX(-1px) translateY(10px) rotate(-45deg) scaleX(0.8); }
  #root .HamburgerButton.back .bar:nth-child(2) {
    transform: scaleX(0);
    opacity: 0; }
  #root .HamburgerButton.back .bar:nth-child(3) {
    transform: translateX(1px) translateY(-9px) rotate(45deg) scaleX(0.8); }

#root .Footer {
  opacity: 0;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  text-align: center;
  background-color: var(--main-white);
  color: var(--main-black); }
  @media (min-width: 768px) {
    #root .Footer {
      padding: var(--space-lg); } }
  @media (min-width: 1100px) {
    #root .Footer {
      padding: var(--space-lg); } }
  #root .Footer .footer-nav {
    color: var(--main-black); }
    #root .Footer .footer-nav .nav-list a {
      color: var(--main-black);
      font-size: var(--text-xs); }
  #root .Footer .footer-copyright {
    color: var(--main-black);
    font-size: var(--text-xs); }

#root .RotateScreen {
  z-index: 7; }

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1; }

.ReactModal__Content {
  position: relative !important;
  bottom: auto !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  overflow: visible !important; }

.ril__outer {
  position: relative !important;
  background-color: transparent !important;
  bottom: auto !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  width: auto !important;
  height: auto !important; }

.ril__inner {
  position: relative !important;
  bottom: auto !important;
  top: auto !important;
  left: auto !important;
  right: auto !important;
  padding-bottom: 1.875rem;
  background-color: white;
  border-radius: 3px; }
  @media (min-width: 768px) {
    .ril__inner {
      padding-bottom: 2.5rem;
      border-radius: 4px; } }
  .ril__inner img {
    transform: none !important;
    position: relative !important;
    bottom: auto !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    display: block !important;
    margin: auto;
    max-width: 90vw;
    max-height: 70vh; }
    @media (min-width: 768px) {
      .ril__inner img {
        max-height: 80vh; } }
    @media (min-width: 1100px) {
      .ril__inner img {
        max-height: 88vh;
        max-width: 92vw; } }

.ril__builtinButton {
  width: 28px !important; }

.ril-caption-content {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: calc(var(--text-md) * 0.9);
  color: #000 !important;
  padding-bottom: 5px !important; }
  @media (min-width: 768px) {
    .ril-caption-content {
      padding-bottom: 10px !important; } }

.ril__toolbar,
.ril__caption {
  background-color: transparent !important; }

.ril__caption {
  background-color: transparent !important; }

.ril__toolbar {
  height: 30px !important;
  width: 30px !important;
  top: -15px !important;
  right: -15px !important;
  left: auto !important;
  background-color: transparent !important; }

.ril__toolbarSide {
  padding: 0 !important;
  margin: 0 !important;
  height: 30px !important; }

.ril__toolbarLeftSide {
  padding: 0 !important;
  margin: 0 !important;
  height: 30px !important; }

.ril-toolbar-right {
  position: absolute;
  padding-right: 0;
  bottom: 0rem;
  height: 30px;
  left: 0 !important; }
  .ril-toolbar-right .ril-toolbar__item {
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #000;
    height: 30px;
    width: 30px;
    line-height: 100%;
    font-size: 10px;
    position: relative;
    text-align: center; }
    .ril-toolbar-right .ril-toolbar__item:hover {
      cursor: pointer;
      background-color: #262626; }
    .ril-toolbar-right .ril-toolbar__item button {
      color: #fff;
      position: relative;
      display: inline-block;
      width: 12px;
      top: 7px;
      right: 1px;
      height: 12px;
      opacity: 1; }

.modal-video {
  background-color: rgba(0, 0, 0, 0.92) !important; }

.grecaptcha-badge {
  display: none !important; }

html {
  box-sizing: border-box;
  font-family: "Fira Sans", Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch; }

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: var(--font-primary);
  background-color: var(--main-white); }
  body.rotate-screen-visible {
    background-color: #000 !important; }

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.only-aria-visible {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  /* added line */
  width: 1px; }

a {
  color: inherit; }

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none; }

li,
dt,
dd {
  margin: 0;
  padding: 0; }

button {
  cursor: pointer; }
