:root {
  --font-primary: Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-secondary: Helvetica Neue, Helvetica, Arial, sans-serif;

  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;

  /* type scale */
  --text-xxs: calc(var(--text-xs) / var(--text-scale-ratio));
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));

  // line-height
  --body-line-height: 1.6;
  --heading-line-height: 1.3;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
}

body {
  // font-size: var(--text-base-size);
  font-size: 1em;
  @include media('>=medium') {
    font-size: 1.05em;
  }
  @include media('>=large') {
    font-size: 1.25em;
  }
  @include media('>=xlarge') {
    font-size: 1.3em;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxxs);
  line-height: var(--heading-line-height);
}

p {
  line-height: var(--body-line-height);
}

// a {
//   color: var(--color-link);

//   &:visited {
//     color: var(--color-link-visited);
//   }
// }

b,
strong {
  font-weight: bold;
}

/* utility classes */
.truncate {
  // truncate text if it exceeds parent
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
